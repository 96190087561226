






















































import {Component, Vue} from "vue-property-decorator";
import AccountSettings from "@/views/settings/AccountSettings.vue";
import PasswordChangeSettings from "@/views/settings/PasswordChangeSettings.vue";
import {mapGetters, mapState} from "vuex";
import {AuthenticationDetails, GlobalStats} from "@/models/user";
import {formatTime} from "@/utils/time-utils";
import ApiService from "@/services/Api.service";

@Component({
    components: {
        AccountSettings,
        PasswordChangeSettings
    },
    computed: {
        ...mapState([
            "authenticationDetails"
        ]),
        ...mapGetters([
            "pendingRequest"
        ])
    }
})
export default class Settings extends Vue {
    authenticationDetails!: AuthenticationDetails
    pendingRequest!: boolean;

    globalStats: GlobalStats | null = null;

    get registrationDate(): string {
        return formatTime(this.authenticationDetails.createdAt);
    }

    get completedTests(): string {
        if (this.globalStats != null)
            return this.globalStats.completedTests.toString();
        else
            return "";
    }

    get testsPerDay(): string {
        if (this.globalStats != null)
            return (Math.floor(this.globalStats.testsPerDay * 100 + 0.5) / 100).toString();
        else
            return "";
    }

    mounted(): void {
        ApiService.getUserGlobalStats().then(stats => this.globalStats = stats);
    }
}
