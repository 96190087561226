













































































import {Component, Vue, Watch} from "vue-property-decorator";
import {TestDefinitionModel} from "@/models/test-definition";
import {mapGetters} from "vuex";
import {SelectOption} from "@/models/common";
import {TestStatsModel} from "@/models/test-stats";
import ProgressChart from "@/views/settings/stats/ProgressChart.vue";

@Component({
    components: {ProgressChart},
    computed: {
        ...mapGetters(["testDefinitionsWithStats", "testStats", "pendingRequest"])
    }
})
export default class TestStats extends Vue {
    testDefinitionsWithStats!: Array<TestDefinitionModel> | null;
    testStats!: TestStatsModel | null;
    pendingRequest!: boolean;

    testDefinitionOptions: Array<SelectOption> = [];
    selectedTestDefinitionId: number | null = null;

    get showNotEnoughTestsWarning(): boolean {
        return this.testStats != null && this.testStats.speedValues.length < 3;
    }

    get showStats(): boolean {
        return this.testStats != null;
    }

    get showCharts(): boolean {
        return !this.showNotEnoughTestsWarning && this.testStats != null;
    }

    mounted(): void {
        this.$store.dispatch("refreshTestDefinitionWithStats");
        this.refreshTestDefinitionOptions();
    }

    @Watch("testDefinitionsWithStats")
    onTestDefinitionsChanged(): void {
        this.refreshTestDefinitionOptions();
    }

    @Watch("selectedTestDefinitionId")
    onTestDefinitionSelected(): void {
        if (this.selectedTestDefinitionId != null)
            this.$store.dispatch("refreshTestStats", this.selectedTestDefinitionId);
    }

    private refreshTestDefinitionOptions(): void {
        const options: Array<SelectOption> = [];

        if (this.testDefinitionsWithStats != null && this.testDefinitionsWithStats.length > 0) {
            for (let i = 0; i < this.testDefinitionsWithStats.length; i++) {
                const item = this.testDefinitionsWithStats[i];
                options.push({value: item.id, text: item.name});
            }
        } else {
            options.push({value: null, text: "No test is available"});
        }

        this.testDefinitionOptions = options;
    }
}
