





























































import {Component} from "vue-property-decorator";
import {mapGetters} from "vuex";
import {WordListModel} from "@/models/words";
import {mixins} from "vue-class-component";
import ValidationHelperMixin from "@/mixins/ValidationHelperMixin";
import {required} from "vuelidate/lib/validators";
import ValidationMessageManager from "@/views/shared/ValidationMessageManager.vue";
import {WordImportData} from "@/store/admin";

@Component({
    components: {ValidationMessageManager},
    computed: mapGetters([
        "currentWordList"
    ]),
    validations: {
        importMode: {
            required
        },
        file: {
            required
        }
    },
    validationMessages: {
        importMode: {
            required: "Mode selection is required."
        },
        file: {
            required: "File is required."
        }
    }
})
export default class WordListImportExport extends mixins(ValidationHelperMixin) {
    currentWordList!: WordListModel | null;

    // Import form
    importMode: "append" | "delete" | null = null;
    updateExistingWords = false;
    file: File | null = null;

    exportWordList(): void {
        if (this.currentWordList == null)
            return;

        this.$store.dispatch("exportWords", this.currentWordList.id);
    }

    importWordList(): void {
        this.triggerValidation();
        if (this.$v.$invalid)
            return;

        const data: WordImportData = {
            mode: this.importMode!,
            updateExisting: this.updateExistingWords,
            file: this.file!
        };

        this.$store.dispatch("importWords", data).then(status => {
            if (status) {
                this.file = null;
                this.$v.$reset();

                this.$bvToast.toast("Word list has been successfully imported.", {
                    title: "Success",
                    variant: "success"
                });
            }
        });
    }
}
