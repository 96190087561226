











import {Component, Vue} from "vue-property-decorator";
import {mapGetters} from "vuex";
import {WordListModel} from "@/models/words";

@Component({
        computed: mapGetters([
            "testDefinitions"
        ])
    })
    export default class TestDefinitions extends Vue {
        testDefinitions!: Array<TestDefinitions>;
        fields = [
            {
                key: "id",
                label: "Identifier"
            },
            {
                key: "name"
            },
            {
                key: "duration"
            },
            {
                key: "isActive"
            }
        ];

        mounted(): void {
            this.$store.dispatch("reloadTestDefinitions");
        }

        clickListItem(clicked: WordListModel): void {
            this.$router.push("/settings/admin/test-definitions/" + clicked.id.toString())
        }
    }
