
















import {Component, Vue} from "vue-property-decorator";
import {AuthenticationDetails} from "@/models/user";
import {mapState} from "vuex";
import DeleteAccount from "@/views/settings/DeleteAccount.vue";

@Component({
        components: {DeleteAccount},
        computed: mapState([
            "authenticationDetails"
        ])
    })
    export default class AccountSettings extends Vue {
        authenticationDetails!: AuthenticationDetails;
        currentDisplayName = "";

        mounted(): void {
            this.currentDisplayName = this.authenticationDetails.displayName;
        }

        changeDisplayName() {
            this.$store.dispatch("changeDisplayName", this.currentDisplayName)
                .catch(error => {
                    console.error(error);
                });
        }
    }
