import { render, staticRenderFns } from "./TestStats.vue?vue&type=template&id=996f8cfe&scoped=true&"
import script from "./TestStats.vue?vue&type=script&lang=ts&"
export * from "./TestStats.vue?vue&type=script&lang=ts&"
import style0 from "./TestStats.vue?vue&type=style&index=0&id=996f8cfe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "996f8cfe",
  null
  
)

export default component.exports