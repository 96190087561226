











import Vue from "vue";
import {Component} from "vue-property-decorator";
import {TestStateModel} from "@/models/tests";
import {mapGetters} from "vuex";

@Component({
    computed: mapGetters([
        "testState",
        "isLoggedIn"
    ])
})
export default class AnonymousUserMessage extends Vue {
    private testState!: TestStateModel | undefined;
    private isLoggedIn!: boolean

    get showMessage(): boolean {
        return this.testState == TestStateModel.FINISHED && !this.isLoggedIn;
    }
}
