



























import {Component, Vue} from "vue-property-decorator";
import TextDisplay from "@/views/typing/test/TextDisplay.vue";
import TextInput from "@/views/typing/test/TextInput.vue";
import {mapGetters} from "vuex";
import {ErrorCodeModel} from "@/models/error";
import {TestModel, TestStateModel} from "@/models/tests";
import LoadingSpinner from "@/views/shared/LoadingSpinner.vue";
import SpeedGauge from "@/views/typing/test/SpeedGauge.vue";

@Component({
    components: {
        SpeedGauge,
        LoadingSpinner,
        TextInput,
        TextDisplay
    },
    computed: {
        ...mapGetters([
            "isLoading",
            "activeTest",
            "testError"
        ])
    }
})
export default class TestPanel extends Vue {
    isLoading!: boolean;
    activeTest!: TestModel | undefined;
    testError!: ErrorCodeModel | null;

    get showOverlay(): boolean {
        return this.showTestLoadingOverlay || this.showTestErrorOverlay || this.showSelectTestOverlay || this.showTestOverOverlay;
    }

    get showTestLoadingOverlay(): boolean {
        return this.isLoading;
    }

    get showTestErrorOverlay(): boolean {
        return this.testError != null;
    }

    get showSelectTestOverlay(): boolean {
        return !this.testError && !this.activeTest;
    }

    get showTestOverOverlay(): boolean {
        return !this.testError && this.activeTest?.state == TestStateModel.FINISHED;
    }
}
