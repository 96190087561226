









import {Component, Vue} from "vue-property-decorator";
import LoadingSpinner from "@/views/shared/LoadingSpinner.vue";

@Component({
    components: {LoadingSpinner},
    mounted() {
        this.$store.dispatch("loadApplication");
    }
})
export default class Loading extends Vue {
}
