



























































import {Component, Watch} from "vue-property-decorator";
import ValidationHelperMixin from "@/mixins/ValidationHelperMixin";
import {maxLength, required} from "vuelidate/lib/validators";
import ValidationMessageManager from "@/views/shared/ValidationMessageManager.vue";
import {mixins} from "vue-class-component";
import {ValidationFailedError} from "@/models/error";
import WordListWords from "@/views/settings/admin/word/WordListWords.vue";
import {WordListModel, WordListType} from "@/models/words";
import {SelectOption} from "@/models/common";
import WordListFixedText from "@/views/settings/admin/word/WordListFixedText.vue";
import {mapGetters} from "vuex";
import WordListImportExport from "@/views/settings/admin/word/WordListImportExport.vue";
import {CreateWordListData} from "@/store/admin";

@Component({
    components: {WordListImportExport, WordListFixedText, WordListWords, ValidationMessageManager},
    computed: {
        ...mapGetters([
            "currentWordList",
            "pendingRequest"
        ])
    },
    validations: {
        name: {
            required,
            maxLength: maxLength(128)
        },
        language: {
            required
        },
        type: {
            required
        }
    },
    validationMessages: {
        name: {
            required: "Name is required.",
            maxLength: "Name length cannot exceel 128 characters."
        },
        language: {
            required: "Language is required."
        },
        type: {
            required: "Type is required."
        }
    }
})
export default class WordList extends mixins(ValidationHelperMixin) {
    currentWordList!: WordListModel | null;
    pendingRequest!: boolean;

    availableLanguages: Array<string> = [];
    availableTypes: SelectOption[] = [];

    // Form
    name = "";
    language = "";
    type: WordListType | null = null;

    private currentWordListId = -1;

    get isCreated(): boolean {
        return this.currentWordListId != -1;
    }

    get typeString(): string {
        return this.type != null ? this.type.toString() : "";
    }

    @Watch("currentWordList")
    onCurrentWordListChanged(current: WordListModel | null) {
        this.currentWordListId = current?.id || -1;
        if (current != null)
            this.loadWordList();
    }

    mounted(): void {
        this.$store.dispatch("getAvailableLanguages").then(languages => this.availableLanguages = languages);
        if (this.$route.params.id) {
            this.currentWordListId = parseInt(this.$route.params.id);
            this.$store.dispatch("setCurrentWordList", this.currentWordListId);
        }

        this.availableTypes = Object.values(WordListType).map(it => {
            return {
                value: it,
                text: it.substr(0, 1).toUpperCase() + it.substr(1).toLowerCase()
            };
        });
    }

    createWordList(): void {
        if (this.isCreated)
            return; // Update is not supported

        this.triggerValidation();
        if (this.$v.$invalid)
            return;

        this.doCreateWordList();
    }

    deleteWordList(): void {
        this.$store.dispatch("deleteWordList", this.currentWordListId).then(() => this.goBack());
    }

    private loadWordList(): void {
        this.name = this.currentWordList!.name;
        this.language = this.currentWordList!.language;
        this.type = this.currentWordList!.type;
    }

    private doCreateWordList(): void {
        const data: CreateWordListData = {
            name: this.name,
            language: this.language,
            type: this.type!
        };

        this.$store.dispatch("createWordList", data).then((created: WordListModel) => {
            this.$store.dispatch("setCurrentWordList", created.id);
            this.$router.replace(this.$route.fullPath.replace("/new", `/${created.id}`));
            this.$v.$reset();
        }).catch(error => {
            if (error instanceof ValidationFailedError)
                this.processValidationError(error);
            else
                console.error(error);
        });
    }

    private goBack(): void {
        this.$router.push("/settings/admin");
    }
}
