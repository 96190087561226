
































































import {Component} from "vue-property-decorator";
import {RegisterData} from "@/store";
import {email, required} from "vuelidate/lib/validators";
import ValidationMessageManager from "@/views/shared/ValidationMessageManager.vue";
import ValidationHelperMixin from "@/mixins/ValidationHelperMixin";
import {mixins} from "vue-class-component";
import {ValidationFailedError} from "@/models/error";

@Component({
        components: {ValidationMessageManager},
        validations: {
            email: {
                required,
                email
            },
            displayName: {
                required
            },
            password: {
                required
            },
            passwordRepeat: {
                required,
                sameAsPassword: function (value) {
                    return (this as Register).password == value;
                }
            }
        },
        validationMessages: {
            email: {
                required: "Email is required.",
                email: "Value must be a valid email address."
            },
            displayName: {
                required: "Display name is required."
            },
            password: {
                required: "Password is required."
            },
            passwordRepeat: {
                required: "Repeated password is required.",
                sameAsPassword: "Repeated password doesn't match the new password."
            }
        }
    })
    export default class Register extends mixins(ValidationHelperMixin) {
        email = "";
        displayName = "";
        password = "";
        passwordRepeat = "";

        unknownError = false;
        registered = false;

        doResetState(name: string): void {
            this.unknownError = false;
            this.resetState(name);
        }

        createNewUser(): void {
            this.triggerValidation();
            if (this.$v.$invalid)
                return;

            const registerData: RegisterData = {
                email: this.email,
                displayName: this.displayName,
                password: this.password
            };

            this.$store.dispatch("register", registerData).then(() => {
                this.registered = true;
            }).catch(error => {
                if (error instanceof ValidationFailedError) {
                    this.processValidationError(error);
                } else {
                    this.unknownError = true;
                }
            });
        }
    }
