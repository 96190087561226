import { render, staticRenderFns } from "./TestBestResults.vue?vue&type=template&id=51ad9080&scoped=true&"
import script from "./TestBestResults.vue?vue&type=script&lang=ts&"
export * from "./TestBestResults.vue?vue&type=script&lang=ts&"
import style0 from "./TestBestResults.vue?vue&type=style&index=0&id=51ad9080&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51ad9080",
  null
  
)

export default component.exports