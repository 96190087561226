













import Header from "@/views/shared/Header.vue";
import {Component, Vue} from "vue-property-decorator";
import {mapState} from "vuex";
import Loading from "@/views/Loading.vue";
import ErrorModal from "@/views/ErrorModal.vue";

@Component({
    components: {ErrorModal, Loading, Header},
    computed: mapState([
        "applicationLoaded"
    ])
})
export default class App extends Vue {
    applicationLoaded!: boolean;
}
