












import {Component, Vue} from "vue-property-decorator";
import {mapGetters} from "vuex";
import {TestModel} from "@/models/tests";

@Component({
    computed: mapGetters(["activeTest", "isTestResetAvailable"])
})
export default class TestReset extends Vue {
    activeTest!: TestModel | null;
    isTestResetAvailable!: boolean;

    get testResetUnavailableText(): string | null {
        if (!this.activeTest)
            return "No test is selected.";
        else if (!this.isTestResetAvailable)
            return "Selected test type doesn't allow reseting word list.";
        else
            return null;
    }

    reset(): void {
        if (this.isTestResetAvailable)
            this.$store.dispatch("resetTest");
    }
}
