











import {Component, Vue} from "vue-property-decorator";
import {mapGetters} from "vuex";
import {WordListModel, WordListType} from "@/models/words";

@Component({
        computed: mapGetters([
            "wordLists"
        ])
    })
    export default class WordLists extends Vue {
        wordLists!: Array<WordListModel>;
        fields = [
            {
                key: "id",
                label: "Identifier"
            },
            {
                key: "name"
            },
            {
                key: "language"
            },
            {
                key: "type",
                formatter: (type: WordListType) => type.substr(0, 1) + type.substr(1).toLowerCase()
            }
        ];

        mounted(): void {
            this.$store.dispatch("reloadWordLists");
        }

        clickListItem(clicked: WordListModel): void {
            this.$router.push("/settings/admin/word-lists/" + clicked.id.toString())
        }
    }
