




























import {Component, Vue, Watch} from "vue-property-decorator";
import {mapGetters} from "vuex";
import {TestBestResultModel, TestResultRangeModel} from "@/models/tests";
import DateService from "@/services/Date.service";
import {TestDefinitionModel} from "@/models/test-definition";
import {formatDuration} from "@/utils/time-utils";
import {BvTableFieldArray} from "bootstrap-vue/esm/components/table";
import TestBestResultsHelp from "@/views/typing/TestBestResultsHelp.vue";

@Component({
    components: {TestBestResultsHelp},
    computed: {
        ...mapGetters(["testBestResults", "activeUserTestDefinition"])
    }
})
export default class TestBestResults extends Vue {
    testBestResults!: TestBestResultModel[];
    activeUserTestDefinition!: TestDefinitionModel | null;

    activeTab = 0;

    get fields(): BvTableFieldArray {
        const fields = [
            {
                key: "no",
                label: "Position"
            },
            {
                key: "userDisplayName",
                label: "User"
            },
            {
                key: "testCreatedAt",
                label: "Date",
                formatter: (date: Date) => DateService.formatDateToString(date)
            },
            {
                key: "speed",
                label: "Speed",
                formatter: (value: number) => `${Math.floor(value + 0.5)} WPM`
            },
            {
                key: "accuracy",
                formatter: (accuracy: number) => `${Math.floor(accuracy * 100 + 0.5)}%`
            },
            {
                key: "durationSeconds",
                label: "Duration",
                formatter: (duration: number) => formatDuration(duration)
            },
            {
                key: "score"
            }
        ];

        if (this.activeUserTestDefinition?.duration != null)
            fields.splice(fields.length - 2, 1);

        return fields;
    }

    @Watch("activeTab")
    onTabChanged(): void {
        let range: TestResultRangeModel;
        switch (this.activeTab) {
            case 0:
                range = TestResultRangeModel.TODAY;
                break;
            case 1:
                range = TestResultRangeModel.LAST_WEEK;
                break;
            case 2:
                range = TestResultRangeModel.LAST_MONTH;
                break;
            default:
                range = TestResultRangeModel.ALL_TIME;
                break;
        }

        this.$store.dispatch("setTestBestResultsRange", range);
    }

    showScoreboardHelp(): void {
        this.$store.commit("showBestResultsHelp");
    }
}
