





















import {Component, Vue} from "vue-property-decorator";
import WordLists from "@/views/settings/admin/word/WordLists.vue";
import TestDefinitions from "@/views/settings/admin/test/TestDefinitions.vue";
import Users from "@/views/settings/admin/user/Users.vue";

@Component({
        components: {Users, TestDefinitions, WordLists}
    })
    export default class AdminSettings extends Vue {

        get testDefinitionsLoading(): boolean {
            return this.$store.getters.hasPendingRequest("loadTestDefinitions");
        }

        get wordListsLoading(): boolean {
            return this.$store.getters.hasPendingRequest("loadWordLists");
        }

        get usersLoading(): boolean {
            return this.$store.getters.hasPendingRequest("loadUsers");
        }
    }
