


































import {Component} from "vue-property-decorator";
import {AuthenticationDetails} from "@/models/user";
import {mapState} from "vuex";
import {required} from "vuelidate/lib/validators";
import {mixins} from "vue-class-component";
import ValidationHelperMixin from "@/mixins/ValidationHelperMixin";
import ValidationMessageManager from "@/views/shared/ValidationMessageManager.vue";
import {ValidationFailedError} from "@/models/error";
import {ChangePasswordData} from "@/store";

@Component({
    components: {ValidationMessageManager},
    computed: mapState([
        "authenticationDetails"
    ]),
    validations: {
        currentPassword: {
            required
        },
        newPassword: {
            required
        },
        newPasswordRepeat: {
            required,
            sameAsPassword: function (value) {
                return (this as PasswordChangeSettings).newPassword == value;
            }
        }
    },
    validationMessages: {
        currentPassword: {
            required: "Current password is required."
        },
        newPassword: {
            required: "New password is required."
        },
        newPasswordRepeat: {
            required: "Repeated password is required.",
            sameAsPassword: "Repeated password doesn't match the new password."
        }
    },
    serverFieldNameMapping: {
        "oldPassword": "currentPassword"
    }
})
export default class PasswordChangeSettings extends mixins(ValidationHelperMixin) {
    authenticationDetails!: AuthenticationDetails;
    currentPassword = "";
    newPassword = "";
    newPasswordRepeat = "";

    changePassword(): void {
        this.triggerValidation();
        if (this.$v.$invalid)
            return;

        const data: ChangePasswordData = {
            oldPassword: this.currentPassword,
            newPassword: this.newPassword
        }

        this.$store.dispatch("changePassword", data)
            .catch(error => {
                if (error instanceof ValidationFailedError) {
                    this.processValidationError(error);
                }
            });
    }
}
