
































import {Component, Vue, Watch} from "vue-property-decorator";
import {mapGetters} from "vuex";

@Component({
    computed: mapGetters(["showTestBestResultsHelp"])
})
export default class TestBestResultsHelp extends Vue {
    showTestBestResultsHelp!: boolean;

    @Watch("showTestBestResultsHelp")
    onShowStateChanged(): void {
        if (this.showTestBestResultsHelp)
            this.$bvModal.show("scoreboard-help-modal");
    }

    onDismissed(): void {
        this.$store.commit("hideBestResultsHelp");
    }
}
