
















































import {TestResultModel} from "@/models/tests";
import {Component, Vue} from "vue-property-decorator";
import {mapGetters} from "vuex";
import {formatDuration} from "@/utils/time-utils";

@Component({
    computed: {
        ...mapGetters(["testResult"])
    }
})
export default class TestSummary extends Vue {
    testResult!: TestResultModel | null;

    get wordsPerMinute(): string {
        if (!this.testResult)
            return "";

        return Math.floor(this.testResult.wordsPerMinute).toString();
    }

    get accuracy(): string {
        if (!this.testResult)
            return "";

        const accuracy = Math.floor(this.testResult.accuracy * 1000);
        return (accuracy / 10).toString();
    }

    get duration(): string {
        if (this.testResult)
            return formatDuration(this.testResult.durationSeconds);
        else
            return "";
    }
}
