


































import {Component, Vue} from "vue-property-decorator";
import {mapGetters} from "vuex";
import {ErrorCodeModel} from "@/models/error";

@Component({
    computed: mapGetters([
        "errorCode",
        "errorRequestName"
    ])
})
export default class ErrorModal extends Vue {
    errorCode!: ErrorCodeModel | null;
    errorRequestName!: string | null;

    get title(): string {
        let title = "Error while executing request";
        if (this.errorRequestName)
            title += ` '${this.errorRequestName}'`;
        return title;
    }

    get hasAdditionalData(): boolean {
        if (!this.errorCode || !this.errorCode.additionalData)
            return false;

        for (let key in this.errorCode.additionalData) {
            if (Object.prototype.hasOwnProperty.call(this.errorCode.additionalData, key))
                return true;
        }

        return false;
    }

    onModalClosed(): void {
        this.$store.commit("clearError");
    }
}
