











import {Component, Vue, Watch} from "vue-property-decorator";
import {mapGetters} from "vuex";
import {TestModel, TestStateModel} from "@/models/tests";
import {formatDuration} from "@/utils/time-utils";
import {TestDefinitionModel} from "@/models/test-definition";

@Component({
    computed: {
        ...mapGetters([
            "activeUserTestDefinition",
            "activeTest",
            "activeTestStarted"
        ])
    }
})
export default class TestTimer extends Vue {
    activeUserTestDefinition!: TestDefinitionModel | undefined;
    activeTest!: TestModel | undefined;
    activeTestStarted!: boolean;

    currentTime = "0:00";
    additionalText = "";

    private intervalId: number | null = null;

    destroyed(): void {
        this.stopTimer();
    }

    @Watch("activeTest")
    onTestChanged(): void {
        this.refreshCounter();
    }

    @Watch("activeTest.state")
    onStateChanged(): void {
        this.refreshCounter();
    }

    private refreshCounter(): void {
        if (!this.activeTest) {
            this.stopTimer();
            return;
        }

        this.additionalText = "";
        this.stopTimer();

        if (this.activeTest.state === TestStateModel.CREATED)
            this.onTestCreated();
        else if (this.activeTestStarted)
            this.onTestStarted();
    }

    private onTestCreated(): void {
        const startDueTime = this.activeTest?.startDueTime;
        if (!startDueTime)
            return;

        this.additionalText = "Remaining time to start:";
        this.createCountdownTimer(startDueTime);
    }

    private onTestStarted(): void {
        if (this.activeUserTestDefinition?.duration != null) {
            const endTime = new Date().getTime() + this.activeUserTestDefinition!.duration * 1000;
            this.createCountdownTimer(new Date(endTime));
        } else {
            this.createForwardTimer();
        }
    }

    private createCountdownTimer(endTime: Date): void {
        let id: number;
        const handler = () => {
            const diff = Math.floor((endTime.getTime() - new Date().getTime()) / 1000);
            if (diff >= 0)
                this.setTime(diff);
            else
                clearInterval(id);
        };

        handler();
        id = setInterval(handler, 1000);
        this.intervalId = id;
    }

    private createForwardTimer(): void {
        const startTime = new Date();
        const handler = () => {
            const now = new Date();
            const diff = Math.floor((now.getTime() - startTime.getTime()) / 1000);
            this.setTime(diff);
        };

        handler();
        this.intervalId = setInterval(handler, 1000);
    }

    private stopTimer(): void {
        if (this.intervalId)
            clearInterval(this.intervalId);
        this.setTime(0);
    }

    private setTime(newTime: number): void {
        this.currentTime = formatDuration(newTime);
    }
}
