



































































import {Component, Watch} from "vue-property-decorator";
import ValidationHelperMixin from "@/mixins/ValidationHelperMixin";
import {maxLength, required, requiredIf} from "vuelidate/lib/validators";
import ValidationMessageManager from "@/views/shared/ValidationMessageManager.vue";
import {mixins} from "vue-class-component";
import {ValidationFailedError} from "@/models/error";
import WordListWords from "@/views/settings/admin/word/WordListWords.vue";
import {TestDefinitionModel} from "@/models/test-definition";
import {mapGetters} from "vuex";
import {WordListModel, WordListType} from "@/models/words";
import {SelectOption} from "@/models/common";
import Vue from "vue";
import {TestDefinitionData} from "@/store/admin";

const requiredIfDurationIsVariable = requiredIf((vm: any, parentVm?: Vue) => (parentVm as TestDefinition)?.model?.variableDuration == true);
const positiveIfDurationIsVariable = (value: any, siblings: Model) => siblings.variableDuration || value > 0;

@Component({
    components: {WordListWords, ValidationMessageManager},
    computed: {
        ...mapGetters([
            "currentTestDefinition",
            "wordLists",
            "pendingRequest"
        ])
    },
    validations: {
        model: {
            name: {
                required,
                maxLength: maxLength(128)
            },
            duration: {
                required: requiredIfDurationIsVariable,
                minValue: positiveIfDurationIsVariable
            },
            wordListId: {
                required
            }
        }
    },
    validationMessages: {
        model: {
            name: {
                required: "Name is required.",
                maxLength: "Name length cannot exceed 128 characters."
            },
            duration: {
                required: "Duration is required.",
                minValue: "Duration must be a positive number."
            },
            wordListId: {
                required: "Word list is required"
            }
        }
    },
    serverFieldNameMapping: {
        name: "model.name",
        duration: "model.duration"
    }
})
export default class TestDefinition extends mixins(ValidationHelperMixin) {
    currentTestDefinition!: TestDefinitionModel | null;
    wordLists!: Array<WordListModel>;
    pendingRequest!: boolean;

    currentTestDefinitionId: number | null = null;

    model = new Model();

    get isCreated(): boolean {
        return this.currentTestDefinitionId != null;
    }

    get wordListOptions(): Array<SelectOption> {
        if (!this.wordLists)
            return [];

        const options: Array<SelectOption> = [];
        for (let i = 0; i < this.wordLists.length; i++) {
            const text = `${this.wordLists[i].name} (${this.wordLists[i].language})`;
            options.push({value: this.wordLists[i].id, text: text});
        }

        return options;
    }

    get showVariableDurationCheckbox(): boolean {
        if (!this.model.wordListId)
            return false;

        let found: WordListModel | null = null;
        for (let wordList of this.wordLists) {
            if (wordList.id == this.model.wordListId) {
                found = wordList;
                break;
            }
        }

        return !!found && found.type == WordListType.FIXED;
    }

    @Watch("currentTestDefinition")
    onCurrentTestDefinitionChanged(current: TestDefinitionModel | null) {
        if (current != null)
            this.loadTestDefinition();
    }

    mounted(): void {
        if (this.$route.params.id) {
            const parsed = parseInt(this.$route.params.id);
            if (!isNaN(parsed)) {
                this.currentTestDefinitionId = parsed;
                this.$store.dispatch("setCurrentTestDefinition", this.currentTestDefinitionId);
            }
        }

        if (this.wordLists.length == 0)
            this.$store.dispatch("reloadWordLists");
    }

    saveButton(): void {
        this.triggerValidation();
        if (this.$v.$invalid)
            return;

        if (this.isCreated)
            this.updateTestDefinition();
        else
            this.createTestDefinition();
    }

    deleteButton(): void {
        this.$store.dispatch("deleteTestDefinition", this.currentTestDefinition!.id).then(() => {
            this.$router.push("/settings/admin");
        });
    }

    private loadTestDefinition(): void {
        if (this.currentTestDefinition == null)
            return;

        this.model.name = this.currentTestDefinition.name;
        this.model.wordListId = this.currentTestDefinition.wordList ? this.currentTestDefinition.wordList.id : null;
        this.model.variableDuration = this.currentTestDefinition.duration == null;
        this.model.duration = this.currentTestDefinition.duration ? this.currentTestDefinition.duration : 0;
        this.model.isActive = this.currentTestDefinition.isActive;
    }

    private createTestDefinition(): void {
        const data: TestDefinitionData = {
            name: this.model.name,
            duration: this.model.variableDuration ? null : this.model.duration,
            wordListId: this.model.wordListId!,
            isActive: this.model.isActive
        };

        this.$store.dispatch("createTestDefinition", data)
            .then(() => {
                this.$router.push("/settings/admin");
            })
            .catch(error => {
                if (error instanceof ValidationFailedError)
                    this.processValidationError(error);
            });
    }

    private updateTestDefinition(): void {
        const data: TestDefinitionData = {
            name: this.model.name,
            duration: this.model.variableDuration ? null : this.model.duration,
            wordListId: this.model.wordListId!,
            isActive: this.model.isActive
        };

        this.$store.dispatch("updateTestDefinition", data)
            .catch(error => {
                if (error instanceof ValidationFailedError)
                    this.processValidationError(error);
            });
    }
}

class Model {
    name = "";
    wordListId: number | null = null;
    variableDuration = false;
    duration = 0;
    isActive = false;
}
