































import {Component, Vue, Watch} from "vue-property-decorator";
import TestPanel from "@/views/typing/test/TestPanel.vue";
import TestSelection from "@/views/typing/TestSelection.vue";
import {TestDefinitionModel} from "@/models/test-definition";
import {mapGetters} from "vuex";
import TestTimer from "@/views/typing/test/TestTimer.vue";
import TestSummary from "@/views/typing/TestSummary.vue";
import TestBestResults from "@/views/typing/TestBestResults.vue";
import AnonymousUserMessage from "@/views/typing/AnonymousUserMessage.vue";

@Component({
        components: {AnonymousUserMessage, TestBestResults, TestSummary, TestTimer, TestSelection, TestPanel},
        computed: {
            ...mapGetters(["activeUserTestDefinition"])
        }
    })
    export default class TypingPage extends Vue {
        activeUserTestDefinition!: TestDefinitionModel | undefined;

        @Watch("activeUserTestDefinition")
        onActiveTestDefinitionChanged(): void {
            if (this.activeUserTestDefinition != null)
                this.$store.dispatch("createTest");
        }
    }
