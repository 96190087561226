































import {Component, Vue} from "vue-property-decorator";
import router from "@/router";

@Component
    export default class Login extends Vue {
        email = "";
        password = "";
        incorrect = false;

        resetIncorrectStatus(): void {
            this.incorrect = false;
        }

        loginUser(event: Event): void {
            event.preventDefault();
            this.$store.dispatch("login", {email: this.email, password: this.password}).then(() => {
                router.push({name: "TestPage"});
            }).catch(() => {
                this.incorrect = true;
            });
        }
    }
