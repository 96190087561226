



























import {Component, Vue} from "vue-property-decorator";
import {mapGetters} from "vuex";

@Component({
        computed: {
            ...mapGetters(["isLoggedIn"])
        }
    })
    export default class Header extends Vue {
        isLoggedIn!: boolean;

        get showLoginButton(): boolean {
            return this.$route.path != "/login"
        }

        goToMainPage(): void {
            this.$router.push("/test");
        }

        openUserPanel(): void {
            this.$router.push("/settings");
        }

        login(): void {
            this.$router.push("/login");
        }

        logout(): void {
            this.$store.dispatch("logout").then(() => {
                this.$router.push("/login");
            });
        }
    }
