







































import {Component, Vue} from "vue-property-decorator";
import {TestDefinitionModel} from "@/models/test-definition";
import {mapGetters} from "vuex";
import {formatDuration} from "@/utils/time-utils";
import TestTimer from "@/views/typing/test/TestTimer.vue";
import TestReset from "@/views/typing/test/TestReset.vue";

@Component({
    components: {TestReset, TestTimer},
    computed: {
        ...mapGetters([
            "isLoading",
            "userTestDefinitions",
            "activeUserTestDefinition"
        ])
    }
})
export default class TestSelection extends Vue {
    isLoading!: boolean;
    userTestDefinitions!: Array<TestDefinitionModel>;
    activeUserTestDefinition!: TestDefinitionModel | null;

    showTestSelector = true;

    get buttonText(): string {
        if (this.activeUserTestDefinition != null)
            return this.activeUserTestDefinition.name;
        else if (this.userTestDefinitions.length > 0)
            return "select a test";
        else
            return "no test available";
    }

    mounted(): void {
        this.$store.dispatch("refreshUserTestDefinitions");
    }

    selectTestDefinition(definition: TestDefinitionModel): void {
        if (!this.isLoading)
            this.$store.commit("setActiveUserTestDefinition", definition);
    }

    formatDuration(duration: number): string {
        return formatDuration(duration);
    }

    mapLanguageToCountryCode(lang: string): string {
        if (lang === "en") {
            return "us";
        } else {
            return lang;
        }
    }
}
