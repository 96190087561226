











































import {Component, Vue, Watch} from "vue-property-decorator";
import {UserModel} from "@/models/user";
import {PageMetadata} from "@/models/pagination";
import {mapGetters} from "vuex";
import DateService from "@/services/Date.service";

@Component({
    computed: {
        ...mapGetters(["users", "usersMetadata"])
    }
})
export default class Users extends Vue {
    users!: UserModel[];
    usersMetadata!: PageMetadata | null;

    userModalTarget: UserModel | null = null;
    inactiveOnly = false;
    fields = [
        {
            key: "id",
            label: "Identifier"
        },
        {
            key: "email"
        },
        {
            key: "displayName"
        },
        {
            key: "createdAt",
            formatter: (date: Date) => DateService.formatDateToString(date)
        },
        {
            key: "activated",
            label: "Activated"
        },
        {
            key: "actions",
            label: "Actions"
        }
    ];

    @Watch("usersMetadata.page")
    onPageChanged(): void {
        if (this.usersMetadata)
            this.$store.dispatch("reloadUserList", this.usersMetadata.page);
    }

    @Watch("inactiveOnly")
    onInactiveOnlyChanged(): void {
        this.$store.commit("setUserInactiveOnly", this.inactiveOnly);
        this.$store.dispatch("reloadUserList");
    }

    mounted(): void {
        this.$store.dispatch("reloadUserList");
    }

    activateUser(user: UserModel): void {
        this.userModalTarget = user;
        this.$nextTick().then(() => this.$bvModal.show("activate-user-modal"));
    }

    deleteUser(user: UserModel): void {
        this.userModalTarget = user;
        this.$nextTick().then(() => this.$bvModal.show("delete-user-modal"));
    }

    doActivateUser(): void {
        if (this.userModalTarget) {
            this.$store.dispatch("activateUser", this.userModalTarget.id);
            this.userModalTarget = null;
        }
    }

    doDeleteUser(): void {
        if (this.userModalTarget) {
            this.$store.dispatch("deleteUser", this.userModalTarget.id);
            this.userModalTarget = null;
        }
    }
}
