






















import {Component, Vue} from "vue-property-decorator";
import {mapState} from "vuex";
import {AuthenticationDetails} from "@/models/user";

const yesButtonTimeout = 5;

@Component({
    computed: mapState(["authenticationDetails"])
})
export default class DeleteAccount extends Vue {
    authenticationDetails!: AuthenticationDetails | null;

    private yesButtonTitle = "Yes"
    private intervalId: number | null = null

    onModalShown(): void {
        if (this.intervalId != null)
            clearInterval(this.intervalId)

        let counter = yesButtonTimeout;
        const handler = () => {
            if (--counter == 0) {
                this.yesButtonTitle = "Yes";
                clearInterval(this.intervalId!);
                this.intervalId = null;
            } else {
                this.yesButtonTitle = `Yes (${counter})`;
            }
        };

        this.yesButtonTitle = `Yes (${yesButtonTimeout})`;
        this.intervalId = setInterval(handler, 1000);
    }

    doDeleteAccount(): void {
        this.$store.dispatch("deleteCurrentUser").then(() => {
            this.$router.push("/");
            window.location.reload();
        });
    }
}
