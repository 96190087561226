
















import {Component, Vue, Watch} from "vue-property-decorator";
import {mapGetters} from "vuex";
import {TestModel, TestStateModel} from "@/models/tests";
import WordService from "@/services/Word.service";
import SpeedCalculator from "@/utils/SpeedCalculator";

const MIN_SPEED = 0;
const MAX_SPEED = 150;
const DISPLAY_STEP = 25;

class Mark {
    readonly text: string;
    readonly showLine: boolean;
    readonly positionPercentage: number;

    constructor(text: string, showLine: boolean, positionPercentage: number) {
        this.text = text;
        this.showLine = showLine;
        this.positionPercentage = positionPercentage;
    }
}

@Component({
    computed: mapGetters([
        "activeTest",
        "enteredWords"
    ])
})
export default class SpeedGauge extends Vue {
    activeTest!: TestModel | null;
    enteredWords!: string[];

    marks: Array<Mark> = [];
    gaugePercentageValue = 0;

    private speedCalculator: SpeedCalculator | null = null;
    private speedRefreshHandle = -1;

    mounted(): void {
        for (let speed = MIN_SPEED; speed <= MAX_SPEED; speed += DISPLAY_STEP) {
            const showLine = speed != MIN_SPEED && speed != MAX_SPEED;
            const percentage = Math.floor(speed / MAX_SPEED * 100);

            const mark = new Mark(speed.toString(), showLine, percentage);
            this.marks.push(mark);
        }
    }

    destroyed(): void {
        if (this.speedRefreshHandle > -1)
            clearInterval(this.speedRefreshHandle);
    }

    @Watch("activeTest")
    private onActiveTestChanged(): void {
        if (this.activeTest && this.activeTest.state == TestStateModel.STARTED && this.activeTest?.startedAt) {
            this.speedCalculator = new SpeedCalculator(this.activeTest?.startedAt, WordService.getCharactersPerWord());
            this.speedRefreshHandle = setInterval(this.refreshSpeed, 1000);
        } else {
            this.speedCalculator = null;
            clearInterval(this.speedRefreshHandle);
            this.speedRefreshHandle = -1;
            this.gaugePercentageValue = 0;
        }
    }

    private refreshSpeed(): void {
        if (this.speedCalculator == null)
            return;

        this.speedCalculator.selectedWords = this.activeTest?.selectedWords.split("|") || [];
        this.speedCalculator.enteredWords = this.enteredWords;

        let speed = this.speedCalculator.calculateSpeed();
        speed = Math.min(MAX_SPEED, Math.max(MIN_SPEED, speed));
        this.gaugePercentageValue = Math.floor(speed / MAX_SPEED * 1000) / 10;
    }
}
